exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-newsitem-2-js": () => import("./../../../src/pages/newsitem2.js" /* webpackChunkName: "component---src-pages-newsitem-2-js" */),
  "component---src-pages-newsitem-3-js": () => import("./../../../src/pages/newsitem3.js" /* webpackChunkName: "component---src-pages-newsitem-3-js" */),
  "component---src-pages-newsitem-js": () => import("./../../../src/pages/newsitem.js" /* webpackChunkName: "component---src-pages-newsitem-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

